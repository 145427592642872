.mainPage {
    display: flex;
    flex-direction: column;
    max-width: 860px;
    margin: 0 auto;
    padding: 5px 53px;
    background: white;
    min-height: 100vh;
    border-radius: 30px;
}

.container {
    display: flex;
    justify-content: space-between;
}

.decorLine {
    width: 100%;
    height: 10px;
    margin: 0 auto;
    background: url(./img/line.svg) 70px 0 no-repeat;
}

.errorContainer {
    padding: 20px;
}

.errorContainer_header {
    text-align: center;
    color: rgb(255, 1, 1);
}

.wrapStatistics {
    position: relative;
}

.wrapDevelopment {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffffb8;
    z-index: 2000;
}
.wrapDevelopment_header {
    color: #5DB075;
}