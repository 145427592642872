.mainPage {
    margin: 0 auto;
    max-width: 860px;
    background: white;
    min-height: 100vh;
    padding: 10px 10px;
    border-radius: 30px;
}

.dressingPage {
    display: flex;
    position: relative;
}

.wrap_back_icon {
    position: absolute;
    right: -20px;
    top: 10px;
}

.wrap_back_icon svg:hover {
    transform: scale(1.5);
}

.wrap_back_icon svg:hover path{
    stroke: red;
}