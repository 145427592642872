.wrapLists{
    position: absolute;
    z-index: 40;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: white;
    min-height: 400px;
    width: 60%;
    max-height: 80%;
    border-radius: 20px;
    padding: 20px 20px 10px;
    display: flex;
    flex-direction: column;
}

.wrapListsHeader{
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.wrapListsHeaderText{
    word-break: break-all;
    margin-right: 10px;
}

.wrapListsHeaderText > span{
    display: block;
    text-align: center;
    padding: 5px;
}

.wrapLists__close{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 43px;
    line-height: 20px;
    color: #5DB075;
    margin: 0;
    cursor: pointer;
}

.wrapLists__close:hover{
    color: red;
}

.wrapLists_date{
    color: #BDBDBD;
}

.wrapLists_theme{
    color: #5DB075;;
}

.search{
    position: relative;
    display: inline-block;
    margin: 0 auto;
}

.search input{
    width: 30vw;
    max-width: 560px;
    height: 43px;
    padding-left: 20px;
    color: #5DB075;
    background: #F6F6F6;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
}

.search__loader{
    position: absolute;
    right: 12px;
    bottom: 12px;
}

.userLists{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 20px 0 2px;
}

.userLists__line{
    border-right: 1px dashed grey;
}

.wrapList{
    flex: 1;
}

.list{
    height: 300px;
    overflow-x: hidden;
}

.list__title p{
    color: #5DB075;
}

.list .list__elem, .list__title{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-left: 30px;
    border-bottom: 1px solid #BDBDBD;
    height: 42px;
}

.user__name{
    color: black;
}

.user__name span{
    color: red;
}

.list__contact{
    position: relative;
    cursor: pointer;
}
.list__contact:hover .user__name{
    color: #5DB075;
}

.disabled .user__name{
    color: #A4A3A3;
}
.disabled:hover .user__name {
    color: #A4A3A3;
}
.disabled::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    cursor: no-drop;
}