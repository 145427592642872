.wrapVisitChar {
    position: relative;
    max-width: 100%;
}

.wrapVisitChar_update {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.rotate {
    animation: rotation 1s infinite linear;
}


@keyframes rotation {
    0% {
        transform:rotate(0deg);
    }
    100% {
        transform:rotate(360deg);
    }
}