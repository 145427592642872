.wrapModalMoveFolder {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.301);
}

.modalMoveFolder{
    width: 420px;
    background: white;
    border: 1px solid grey;
    box-shadow: 0px 0px 10px 1px #9c9c9c;
}

.modal_header {
    width: 100%;
    height: 50px;
    background: #dcdcdc4a;
    border-bottom: 1px solid grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.modal_body {
    position: relative;
    width: 100%;
    height: 200px;
    overflow-y: auto;
}

.noData {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.spinner img{
    width: 120px;
}

.body_row{
    display: flex;
    padding: 5px 10px;
}

.body_row:hover{
    cursor: pointer;
    background: #dcdcdc4a;
}

.body_row p{
    flex: 1;
    text-align: start;
    user-select: none;
}

.body_row{
    display: flex;
    padding: 5px 10px;
}

.row_file {
    position: relative;
}

.row_file::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffffa6;
}

.row_file:hover::before { 
    content: attr(data-title);
    position: absolute;
    z-index: 100;
    background: rgba(110, 109, 109, 0.795);
    bottom: 0;
    left: 10px;
    font-size: 12px;
    transform: translateY(100%);
    display: inline-block;
    padding: 2px;
    color: white;
   }

.wrapModalMoveFolder .row_icon{
    width: 26px;
    height: 26px;
    align-self: center;
    transform: initial;
}

.wrapModalMoveFolder .row_icon:hover{
    transform: initial;
}

.modal_footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    border-top: 1px solid grey;
    padding: 5px 20px;
}

.modal_footer_btnMove{
    color: white;
    border: none;
    padding: 5px 10px;
    background: #5DB075;
    text-transform: uppercase;
    cursor: pointer;
}

.modal_footer_btnMove:active{
    transform: scale(0.99);
}

.spinnerContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}