.wrapper{
    padding: 30px 0;
    overflow-x: hidden;
    height: 100%;
}

.documents{
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    
}

.header_docs p{
    display: inline;
    white-space: nowrap;
    user-select: none;
    padding: 0;
    margin: 0;
}

.header_docs p:hover{
    cursor: pointer;
}

/* .header_docs th:last-child p{
    margin-left: 20px;
} */

.documents th,
.documents td{
    border-bottom: 1px solid #C8C8C8;
    border-right: 1px dashed #C8C8C8;
    padding: 5px 5px;
    word-break: break-word;
}

.documents td {
    color: black;
    text-align: left;
}

.documents td:hover{
    cursor: pointer;
}

.documents td img{
    width: 35px;
    height: 35px;
}

.documents img:hover {
    transform: scale(2.5)
}

.header_thead tr {
    display: inline-table;
    width: 100%;
}


.load,
.spinnerContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinnerContainer td {
    border: 0;
}

.spinnerContainer td img {
    width: 150px;
    height: 150px;
}


.specifyContainer {
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
}

.deleteConfContainer {
    position: fixed;
    top: 30vh;
    left: 35vw;
    width: 30vw;
    padding: 30px;
    background: #FFF;
    border-radius: 12px;
}

.deleteConfContainer p {
    margin-bottom: 20px;
}

.yesBtn,
.noBtn {
    width: 30%;
    border-radius: 15px;
    padding: 4px;
    color: black;
    cursor: pointer;
}

.yesBtn {
    background: rgba(0, 212, 35, 0.1);
    border: 1px solid rgba(0, 212, 35, 0.4);
}

.noBtn {
    float: right;
    background: rgba(255, 0, 0, 0.1);
    border: 1px solid rgba(255, 0, 0, 0.2);
}

.documents .colNumber {
    width: 3.15%;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.colSelect {
    width: 3%;
}

.documents .colSelect:hover {
    cursor: auto;
}

.colName {
    width: 61.3%;
}

.colFileIcon {
    width: 5.14%;
}

.colFileName {
    width: 56.16%;
}

.colModifiedTime {
    width: 17.1%;
}

.colSize {
    width: 8.45%;
}

.colType {
    width: 7%;
}

.colCheckbox:hover {
 cursor: pointer;
}

@media (max-width: 1440px) {
    tr * {
        font-size: 16px;
    }
}

@media (max-width: 1280px) {
    tr * {
        font-size: 14px;
    }
}

@media (max-width: 1024px) {
    tr * {
        font-size: 12px;
    }
    .documents td img{
        width: 32px;
        height: 32px;
     }
}

@media (max-width: 768px) {
    .documents tr{
        font-size: 12px;
    }

    .documents td img{
        width: 30px;
        height: 30px;
     }
}

@media (max-width: 580px) {
    .documents tr{
        font-size: 10px;
    }

    .documents td img{
        width: 20px;
        height: 20px;
     }

    .documents th{
        padding: 5px 0px;
    }
    
    .documents td{
        padding: 5px 0px;
    }
}

@media (max-width: 480px) {
    .documents tr{
        font-size: 8px;
    }

    .documents td img{
        width: 15px;
        height: 15px;
     }

    .documents th{
        border-bottom: 1px solid grey;
        padding: 2px 0px;
    }
    
    .documents td{
        border-bottom: 1px solid grey;
        padding: 2px 0px;
    }
}

@media (max-width: 375px) {
    .documents tr{
        font-size: 7px;
    }

    .documents td img{
        width: 10px;
        height: 10px;
     }

}