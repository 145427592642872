.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 40px;;
}

.container_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 147px;
    height: 100%;

    background: #5DB075;

    border: 1px solid #E8E8E8;
    border-radius: 8px;
}

.container_icon p {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 10px;

    color: #FFFFFF;
}

.icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 70%;
    margin: 5px;

    background: #F6F6F6;

    border: 1px solid #E8E8E8;
    box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}
.icon svg {
    width: 60%;
}

.wrap_search{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 55px;
}

.page_title {
    font-size: 24px;
    line-height: 29px;
    padding: 0;
    margin: 0 0 65px 0;

    color: #5DB075;
}

.labelFile input[type=file],
.labelFile input[type=file]:focus {
    width: 0;
    height: 0;

    border: none;
    outline:0;
    opacity:0;
    pointer-events:none;
    user-select:none;
}

.labelFile {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 220px;
    height: 50px;
    margin-bottom: 15px;
    padding: 0 1.2em;

    border:2px dashed #808080;
    border-radius:5px;
    transition: border 300ms ease;
    cursor: pointer;
    text-align: center;
}

.labelFile i {
    display:block;
    font-size:42px;
    padding-bottom:16px
}

.labelFile .title {
    color:grey;
    transition:200ms color
}

.labelFile:hover{
    border:2px solid #5DB075
}

.labelFile:hover .title,
.labelFile:hover path {
    fill: #5DB075;
    color:#5DB075
}

.search_form {
    position: relative;
}

.search_form label{
    display: block;
    margin-left: 20px;
    margin-bottom: 5px;
}

.search_form input {
    width: 70vw;
    max-width: 560px;
    height: 43px;
    padding-left: 20px;
    color: #5DB075;
      background: #F6F6F6;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
}

.search_form img {
    position: absolute;
    right: 15px;
    bottom: 15px;
}


.active{
    display: flex;
    width: 365px;
    height: 25px;
    overflow: hidden;
    background: #F9F9F9;
    border: 1px solid #00B628;
    padding-right: 5px;
}

.load{
    animation: rotateLoadIcon 1.5s infinite linear;
    background-color: transparent;
}

.hidden {
    display: none;
}

.wrap_back_icon {
    position: absolute;
    right: -10px;
    top: -10px;
}

.wrap_back_icon svg:hover {
    transform: scale(1.5);
}

.wrap_back_icon svg:hover path{
    stroke: red;
}

@keyframes rotateLoadIcon {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 768px) {
    
    .container_icon{
        width: 130px;
        height: 130px;
    }

    .container_icon img{
        width: 60px;
    }


    .container_icon p{
        font-size: 13px;
    }
    
    .search img{
        width: 12px;
        height: 12px;
    }

    .page_title {
        margin-bottom: 15px;
    }
    
    .active{
        width: 300px;
    }

    .wrap_search_icon img{
        width: 60px;
    }

    .wrap_search_icon p{
        font-size: 14px;
    }

    .wrap_back_icon img{
        width: 60px;
    }
    .wrap_back_icon p{
        font-size: 14px;
    }
}

@media (max-width: 580px) {
    
    .container_icon{
        width: 100px;
        height: 100px;
    }

    .container_icon img{
        width: 40px;
    }

    .container_icon p{
        margin-top: 2.5px;
        font-size: 9px;
    }
    
    .search img{
        width: 10px;
        height: 10px;
    }
    
    .active{
        width: 180px;
    }

    .active input{
        width: 160px;
    }

    .wrap_search_icon img{
        width: 30px;
    }

    .wrap_search_icon p{
        font-size: 10px;
    }

    .wrap_back_icon img{
        width: 30px;
    }
    .wrap_back_icon p{
        font-size: 10px;
    }
}
@media (max-width: 411px) {
    
    .container_icon{
        width: 50px;
        height: 50px;
    }

    .container_icon img{
        width: 30px;
    }

    .container_icon p{
        font-size: 5px;
    }
    
    .search img{
        width: 10px;
        height: 10px;
    }
    
    .active{
        width: 180px;
    }

    .wrap_search_icon img{
        width: 25px;
    }

    .wrap_search_icon p{
        font-size: 6px;
    }

    .wrap_back_icon img{
        width: 25px;
    }
    .wrap_back_icon p{
        font-size: 6px;
    }
}

@media (max-height: 750px) {
    .page_title {
        margin-top: 10px;
    }
    .wrapper {
        margin: 15px 35px 15px 20px;
    }

    .page_title {
        font-size: 18px;
        margin-bottom: 30px;
    }

    .container_icon {
        padding-bottom: 5px;
    }
}