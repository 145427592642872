.documentsPage{
    width: 90vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 50px;
    background: white;
    border-radius: 30px;
}

.documentsPage  .headerNoFiles{
    text-align: center;
    padding: 40px;
}

.backgroundProgress{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(128, 128, 128, 0.274);
}

.wrapProgress{
    border-radius: 10px;
    padding: 10px;
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
}

.progress_bar {
    align-self: flex-start;
    width: 0;
    height: 10px;
    background: #5DB075;
    margin-bottom: 10px;
  }
  
  .count {
    line-height: normal;
    margin: 10px 0;
    text-align: center;
    font-weight: 100;
    font-size: 24px;
    color: #5DB075;
  }

@media (max-width: 1250px) {
    .documentsPage {
        padding: 0;
    }
}