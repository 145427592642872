.wrapDirectory{
    position: relative;
    display: flex;
}

.controlDirectory {
    display: flex;
    align-items: center;
    padding: 5px 10px 5px 0px;
}

.controlDirectory div{
    display: flex;
    align-items: center;
}

.controlDirectory svg:hover {
    cursor: pointer;
}

.controlDirectory svg:hover path{
    fill: #5DB075;
}

.currentDirectory{
    display: flex;
    flex-wrap: wrap;
    padding: 0px 10px;
    flex: 1;
}

.currentDirectory > p{
    padding: 0;
    display: flex;
    align-items: center;
}

.currentDirectory > p span{
    font-size: 22px;
}

.crossModal {
    display: flex;
    align-items: center;
}

.crossModal svg:hover {
    cursor: pointer;
}

.crossModal svg:hover path {
    fill: red;
}


