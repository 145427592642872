.wrapTableCompanyMeetings {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 34px 0 20px;
    margin-top: 20px;
}

.overflow {
    overflow-x: hidden;
    max-height: 55vh;
    padding-left: 15px;
}

.wrapTableCompanyMeetings_header {
    text-align: center;
    margin-bottom: 20px;
}

.noMeetingsText {
    text-align: center;
    padding-top: 20px;
}

.tableCompanyMeetings {
    border-collapse: collapse;
    width: 100%;
}

.tableHeader{
    position: absolute;
    top: 0;
    width: 100%;
    height: 34px;
    left: 15px;
}

.tableHeader tr{
    display: inline-table;
    width: 100%;
}

.tableCompanyMeetings th,
.tableCompanyMeetings td {
    border-bottom: 1px solid #C8C8C8;
    border-right: 1px dashed #C8C8C8;
    padding: 7px 5px;
    word-break: break-word;
}

.tableCompanyMeetings th,
.tableCompanyMeetings td {
    text-align: center;
}

.tableCompanyMeetings th:last-child,
.tableCompanyMeetings td:last-child {
    border-right: none;
    text-align: center;
}

.tableHeaderCell{
    color: #5DB075;
}

.cellData{
    width: 11%;
}

.cellOnline{
    width: 7%;
}

td.cellOnline > div{
    width: 15px;
    height: 15px;
    background: #c7c7c7;
    border-radius: 50%;
    margin: 0 auto;
}

td.online > div{
    background: #5DB075;
}

.btns_confirm{
    display: flex;
    align-items: center;
}

.btns_confirm .themeApply{
    margin: 0 5px;
}

.btns_confirm .themeApply:hover{
    border: 1px solid #5DB075;
}

.btns_confirm .themeCancel:hover{
    border: 1px solid #FF0000;
}

.btns_confirm > button{
    background: transparent;
    border: 1px solid #E8E8E8;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 2px;
}


.cellTheme__edit{
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1px solid #E8E8E8;
    width: 25px;
    height: 25px;
    float: right;
    cursor: pointer;
    border-radius: 50%;
}

.cellTheme__edit:hover{
    border: 1px solid #5DB075;
}

.btns_confirm > button > svg, .cellTheme__edit > svg{
    width: 80%;
    height: 80%;
}

.tableCompanyMeetings .cellTheme{
    width: 34%;
    text-align: start;
    padding-left: 15px;
}

.cellParticipants{
    width: 15%;
}
.cellDuration{
    width: 12%;
}

.tableBodyRow .cellParticipants_decoration{
    color: #5DB075;
    text-decoration: underline;
    cursor: pointer;
}
/* .tableBody{
    margin-top: 34px;
} */

.tableBodyRow {
    position: relative;
}

.activeVideo{
    cursor: pointer;
}

.tableBodyRow *{
    color: black;
}

.tableBody_cellIcon img {
    margin: 0 auto;
    display: block;
    width: 35px;
    height: 35px;
}

.tableBody_cellStatus img {
    margin: 0 auto;
    display: block;
    width: 20px;
    height: 20px;
}

.modal {
    position: fixed;
    top: 30vh;
    left: 35vw;
    width: 30vw;
    padding: 30px;
    background: #FFF;
    border-radius: 12px;
    border: 2px solid #7C7C7C;
    z-index: 1;
}

.modal p {
    margin-bottom: 20px;
}

.modal_yesBtn,
.modal_noBtn {
    width: 30%;
    border-radius: 15px;
    padding: 4px;
    color: black;
    cursor: pointer;
}

.edit .modal_yesBtn {
    width: 42%;
}
.edit p {
    margin-bottom: 0px;
}

.modal_yesBtn {
    background: rgba(0, 212, 35, 0.1);
    border: 1px solid rgba(0, 212, 35, 0.4);
}

.modal_noBtn {
    float: right;
    background: rgba(255, 0, 0, 0.1);
    border: 1px solid rgba(255, 0, 0, 0.2);
}

.modalEditForm {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
}

.modalEditForm label {
    display: flex;
    align-items: center;
}

.modalEditForm input {
    margin-left: 20px;
}

.modalEditForm .modalEditForm_checkboxConf {
    margin-bottom: 20px;    
}
.modalEditForm_textTheme input{
    width: 100%;  
}

.modalEditForm_checkboxConf input {
    width: 20px;
    height: 20px;  
}

.wrapEnterPassword{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.24);
    z-index: 21;
}

.enterPassword{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 20px;
    background: white;
    border: 1px solid #5DB075;
    padding: 25px;
    width: 335px;
}

.fieldPassword > input, .enterPassword form >  button{
    margin: 0 auto;
    display: block;
}

.enterPassword form > button{
    padding: 10px;
}

.enterPassword form > p{
    text-align: center;
}
.fieldPassword > input{
    border: 1px solid black;
    margin: 20px auto;
    padding: 2px 5px;
    padding-right: 25px;
    width: 230px;
    box-sizing: border-box;
}

.fieldPassword{
    position: relative;
    display: block;
}

.fieldPassword__icon{
    position: absolute;
    top: -2px;
    right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.enterPassword__close{
    position: absolute;
    top: 6px;
    right: 8px;
    font-size: 30px;
    line-height: 20px;
    color: #5DB075;
    margin: 0;
    cursor: pointer;
}

.enterPassword__close:hover{
    color: red;
}

