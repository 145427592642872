.wrapPagination{
  display: flex;
  justify-content: center;
}
.wrapBtns{
  display: flex;
  margin: 20px 0 10px;
}

.wrapBtns button{
  width: 40px;
  height: 40px;
  line-height: 0;
  border: none;
  border-radius: 5px;
  background: white;
  color: #5db276;
  font-weight: bold;
  padding: 10px;
  margin: 10px 5px;
  box-shadow: 0 0 5px rgba(154, 147, 140, 1);
}

.wrapBtns button:hover{
  cursor: pointer;
  background: rgb(240, 240, 240);
  box-shadow: inset 0 0 5px rgba(154, 147, 140, 0.5), 1px 1px 5px rgba(255, 255, 255, 1);
}