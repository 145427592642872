.wrapContextMenu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.contextMenu{
    list-style: none;
    border: 1px solid #808080;
    background-color: white;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.6);
    z-index: 10;
}

.contextMenu img{
    width: 36px;
}

.contextMenu button{
    width: 100%;
    display: flex;  
    background: rgb(255, 255, 255);
    padding: 5px;
    border: none;
    border-bottom: 1px solid grey;
}

.contextMenu button:hover{
    cursor: pointer;
    background: rgb(240, 240, 240);
}

.contextMenu li a{
        width: 100%;
}
.contextMenu li:last-child button{
        border: none;
}

.contextMenu p{
    white-space: nowrap;
}

.renameModal input{
    margin: 10px;
    padding: 3px;
}

.renameModal div{
    display: flex;
    justify-content: flex-end;
}
.renameModal button{
    width: auto;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 10px;
}

.renameModal button:last-child{
    background: #4285f4;
    color: #ffffff;
}

.renameModal button:first-child{
    background: #e90e0e;
    color: #ffffff;
}

.renameModal button:first-child:hover{
    background: #c40c0c;
}
.renameModal button:last-child:hover{
    background: #2d61b4;
}


.labelFile input[type=file],
.labelFile input[type=file]:focus {
    width: 0;
    height: 0;
    border: none;
    outline:0;
    opacity:0;
    pointer-events:none;
    user-select:none;
}
.labelFile:hover{
    cursor: pointer;
    background: rgb(240, 240, 240);
}

.labelFile {
    width: 100%;
    display: flex;  
    background: rgb(255, 255, 255);
    padding: 5px;
    border: none;
    border-bottom: 1px solid grey;
}

.labelFile i {
    display:block;
    font-size:42px;
    padding-bottom:16px
}

@media (max-width: 768px){
    .contextMenu{
        max-width: 320px;
    }

    .contextMenu button{
        padding: 4px;
    }

    .contextMenu img{
        width: 26px;
    }
    .contextMenu p{
        font-size: 12px;
    }
    .renameModal input{
        font-size: 12px;
    }

    .renameModal button{
        font-size: 12px;
    }
}

@media (max-width: 411px){
    .contextMenu img{
        width: 16px;
    }
    .contextMenu p{
        font-size: 8px;
    }

    .renameModal input{
        font-size: 8px;
    }

    .renameModal button{
        font-size: 8px;
    }
}
