.container {
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.container:hover {
    background-color: rgba(167, 255, 194, 0.22);
    border-radius: 10px;
}

.image {
    width: 45px;
    height: 45px;
    margin: 5px 30px 5px 0;
}