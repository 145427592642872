h1 {
    font-size: 22px;
    margin: 10px 0 0 5px;
    font-weight: normal;
}

.container {
    margin-bottom: 30px;
}

.pageTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;

    color: #7C7C7C;
}

.containerStandList {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    /*max-height: 640px;*/
    /*overflow: scroll;*/
}

.warning {
    color: #f31313;
}

.btnAllStand {
    font-size: 16px;
    text-align: left;
    margin: 4px;
    padding: 24px 20px;
    width: 48%;
    height: auto;

    color: #A4A3A3;
    background: #F6F6F6;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    cursor: pointer;
}

.btnAllStand:hover {
    color: #5DB075;
}

.btnAllStand:active {
    color: #48895b;
}


.selected {
    color: #5DB075;
    box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.standItem {
    display: flex;

    margin: 4px;
    padding: 14px 20px;
    width: 48%;

    background: #F6F6F6;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    cursor: pointer;
}

@media (max-width: 990px) {
    .standItem {
        width: 48%;
    }
}

@media (max-width: 600px) {
    .standItem {
        width: 98%;
    }
}