.container {
    margin-left: 10px;
    margin-top: 30px;
}

.containerSelectIndicator,
.containerSelectDate {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.dateContainer {
    display: flex;
    max-width: 460px;
    height: 50px;
    align-items: center;
    padding: 0 0 6px;

    box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.15);
}

.dateContainer:first-child {
    border-right: 1px solid #7C7C7C;
}

.pageTitle {
    width: content-box;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 0 20px 0 0;
}

.wrapIndicatorsContainer,
.dateContainer {
    position: relative;
    width: 550px;
    min-height: 50px;
    background: #F6F6F6;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
}

.wrapIndicatorsContainer {
    width: 300px;
    height: 50px;
    border: none;
}

.indicatorsContainer {
    position: relative;
    background: #F6F6F6;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    padding: 2px;
}

.indicatorsContainer_open {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
}

.additionalFields {
    position: relative;
}

/*.additionalFields::after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    background: #ffffff7d;*/
/*}*/

.btnContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 5px;
    top: 5px;
    width: 39px;
    height: 40px;
    z-index: 1000;
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
}

.selectedIndicator {
    font-size: 18px;
    line-height: 22px;
    padding: 12px 19px;
    text-align: start;
}

.selectedIndicator:hover {
    /*background: #efefef;*/
    color: #5DB075;
}

.additionalFields .selectedIndicator {
    cursor: pointer;
}

.selectIndicator_open {
    position: absolute;
    left: -1px;
    right: -1px;
    z-index: 1;
    background: #F6F6F6;
    border: 1px solid #E8E8E8;
    border-radius: 0 0 8px 8px;
    border-top: none;
}

.active {
    color: #5DB075;
}

.backgroundList{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.backgroundListNone{
    display: none;
}