.container {
    width: 46%;
    margin-right: 40px;
}

.decorLine {
    width: 100%;
    height: 10px;
    background: url(./img/line.svg) 0 0 no-repeat;
    margin: 2rem 0;
}

.pageTitle {
    font-weight: 500;
    font-size: 24px;
    color: #5DB075;
    margin: 2rem 0;
}

.subtitlePage {
    font-weight: 500;
    font-size: 24px;
    color: #ACACAC;
    margin: 2rem 0;
}

.genderList {
    display: flex;
    justify-content: space-between;
}

.iconContainer {
    width: 47px;
    cursor: pointer;
}


.genderTitle {
    font-size: 16px;
    color: #ACACAC;
}

.namesContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.name, .selectedName {
    margin-bottom: 17px;
    padding: 5px 30px;

    background: #F6F6F6;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    color: #ACACAC;
    cursor: pointer;
}

.selectedName {
    color: #5DB075;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 97px;
    height: 91px;

    background: #F6F6F6;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
}


.selectContainer {
    margin-bottom: 20px;
}

.select {
    width: 100%;
    height: 40px;
    margin-top: 6px;

    color: #ACACAC;
    background: #F6F6F6;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
}

.select:focus-visible {
    outline: none;
}

option {
    height: 40px;
    background:  #F6F6F6;
}


/*.inputItem {*/
/*    display: block;*/
/*    width: 100%;*/
/*    height: 40px;*/
/*    margin-top: 6px;*/
/*    margin-bottom: 17px;*/
/*    padding-left: 17px;*/

/*    color: #5DB075;*/

/*    background: #F6F6F6;*/
/*    border: 1px solid #E8E8E8;*/
/*    box-sizing: border-box;*/
/*    border-radius: 8px;*/
/*}*/

.label {
    display: block;
    line-height: 19px;
    color:  #A0A0A0;
}

.saveBtn,
.disabledBtn {
    width: 100%;
    height: 46px;
    margin-bottom: 2em;
    border-radius: 30px;
    border-style: none;
    color: white;
}

.disabledBtn {
    background: #727372;
    cursor: not-allowed;
}















