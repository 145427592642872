.mediaPage {
    position: absolute;
    width: 100%;
    background: white;
}

.closeWrapper {
    position: absolute;
    right: 17px;
    top: 17px;
    margin-top: 0;
    text-align: right;
    font-size: 0;
    z-index: 2000;
}

.closeImg {
    cursor: pointer;
    user-select: none;
}

.editingButton {
    height: 32px;
    z-index: 2;
    color: #fff;
    background: #5DB075;
    border: 1px solid #f2f2f2;
    border-radius: 0.35rem;
    cursor: pointer;
    margin: 15px 20px;
    padding: 0 10px;
}

.editingButton:disabled {
    background: #727372;
    cursor: not-allowed;
}

.editingButton:hover {
    background: #217645;
}

.mediaPage__companyName{
    margin: 15px 20px;
}

.mediaContainer {
    height: 100%;
    padding-top: 60px;
    text-align: center;
}

.standLoadingWindow {
    position: fixed;
    background: rgba(255,255,255, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loadingText,
.progressNumber {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    margin: 20px 10px;
}

.backgroundWrapper {
    width: 50%;
    height: 30px;
    margin: 10px auto;
    border: 2px solid #727372;
    border-radius: 15px;
}

.loadingBackground {
    background: #217645;
    height: 100%;
    border-radius: 15px;
}
/* 
.loadingText {
    margin-top: 0;
} */

.errorWrapper {
    width: 30%;
    margin: 100px auto;
}

.errorText {
    font-size: 30px;
    color: red;
    text-align: center;
}

/*video*/
.videoWrapper {
    max-width: 100%;
    max-height: calc(100vh - 171px);
    user-select: none;
}

/*videoIframe*/
.videoIframe {
    width: 80vw;
    height: 80vh;
    padding-top: calc(20vh - 80px);
    margin: 0 auto;

    border: none;
    user-select: none;
}

/*iframe*/
.iframe {
    height: 100%;
    width: 100%;
    border: none;
}

/*image*/
.image {
    max-width: 100%;
    max-height: 80vh;
    user-select: none;
}

/*files*/

.mediaContainer h2 {
    color: rgb(236, 9, 9);
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}

.filesList {
    padding: 40px calc(50% - 550px) 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    overflow: hidden;
}

.linkStyled {
    cursor: pointer;
    width: 100%;
    display: flex;
    border-bottom: 1px solid lightgray;
    -webkit-box-align: center;
    align-items: center;
    font-size: 18px;
    color: rgb(60, 60, 60);
    padding: 10px;
}

.linkStyled:hover {
    background: rgba(177, 255, 165, 0.62);
}

.linkStyled img {
    height: 40px;
}

.linkStyled div {
    font-size: 16px;
}

.wrapControlArrows{
    position: fixed;
    bottom: 110px;
    right: 110px;
    width: 30px;
    height: 30px;
}