.wrapListParticipants{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.24);
    z-index: 21;
}

.listParticipants{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 20px;
    background: white;
    overflow: hidden;
    border: 1px solid #5DB075;
    min-width: 230px;
    max-width: 500px;
    z-index: 30;
}

.listParticipantsHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #5DB075;
    padding: 5px;
} 

.listParticipantsHeader_back{
    cursor: pointer;
    padding: 5px;
}

.listParticipantsHeader_back path{
    color: white;
}

.listParticipantsHeader_back:hover path{
    color: red;
}

.listParticipantsHeader p{
    margin: 0 auto;
    color: white;
    padding: 5px;
} 

.wrapHeaderContolsBtns{
    position: relative;
    user-select: none;
}

.headerControlBtnOpen{
    width: 35px;
    height: 35px;
    color: grey;
    background: transparent;
    border-radius: 50%;
    padding: 7px;
    text-align: center;
    border: 1px solid transparent;
    user-select: none;
}

.headerControlBtnOpen:hover {
    cursor: pointer;
    border: 1px solid white;
}

.headerControlBtnOpen svg {
    height: 100%;
}

.activeControl{
    border: 1px solid white;
}

.wrapControl{
    position: absolute;
    top: 26px;
    right: 26px;
    border-radius: 10px;
    background: white;
    padding: 10px 0px;
    z-index: 4;
    border: 1px solid #5DB075;
    min-width: 140px;
}

.wrapControl__btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    width: 100%;
    background: transparent;
    border: none;
    color: rgb(112,117,121);
}
.wrapControl__btn:hover {
    cursor: pointer;
    background: rgb(244,244,245);
}
.wrapControl__btn svg {
    width: 25px;
    margin-right: 25px;
}

.btnRed span{
    color: red;
}

.headerControlBtnClose{
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 3;
    cursor: default;
}



.listParticipantsContent{
    position: relative;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 10px;
}

.listParticipantsContent .list > p{
    text-align: center;
    padding: 10px;
}

.participant{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border-bottom: 1px solid #BDBDBD;
}

.participant__name{
    color: black;
    padding: 1px 5px;
}

.participant__name span{
    color: red;
}

.participant__deleteBtn{
    background: #BDBDBD;
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 5px;
    min-width: 20px;
}

.participant__deleteBtn:hover{
    background: #5DB075;
}

.participant__deleteBtn span {
    line-height: 18px;
    width: 100%;
    height: 100%;
    display: inline-block;
    font-size: 24px;
    color: white;
}

.paginationBtns {
    margin: 10px 0;
}

.paginationBtns button{
    width: 25px;
    height: 25px;
    padding: 1px;
}