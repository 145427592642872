.exitContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 0;
    top: 10px;
    width: 90px;
    padding: 5px 10px 5px 10px;
    margin: 10px 0 20px;
    background: #dedede;
    border: 1px solid #dedede;
    border-radius: 6px;
    color: #808080;
}

.exitContainer:hover svg {
    transform: scale(1.2);
}

.exitContainer:hover svg path{
    fill: red;
}

.exitContainer p {
    font-size: 15px;
    padding: 0;
    margin-left: 5px;
}

.exitContainer:hover p {
    color: red;
}