.wrapDirectory{
    position: relative;
    display: flex;
    margin: 10px 0px;
}

.controlDirectory {
    display: flex;
    align-items: center;
    padding: 5px 10px;
}

.controlDirectory svg:hover {
    cursor: pointer;
}

.controlDirectory svg:hover path{
    fill: #5DB075;
}

.currentDirectory{
    display: flex;
    flex-wrap: wrap;
    padding: 0px 10px;
    border: 0.5px solid #7c7c7c;
    flex: 1;
}

.currentDirectory > p{
    padding: 0;
    display: flex;
    align-items: center;
}

.currentDirectory > p span:first-child{
    padding: 5px 5px;
    border-radius: 5px;
}

.currentDirectory > p span:first-child:hover{
    cursor: pointer;
    background: #7c7c7c2d;
}

.currentDirectory > p span:first-child:active{
    cursor: pointer;
    background: #5DB0752d;
    color: #5DB075;
}

.currentDirectory > p span:last-child{
    display: flex;
    padding: 0 5px;
}

.currentDirectory .currentFolder {
    position: relative;
}

.currentDirectory .currentFolder > p{
    padding: 0;
    display: flex;
    align-items: center;
}

.currentDirectory .currentFolder > p span{
    display: flex;
    padding: 0 0 0 10px;
}

.currentDirectory .currentFolder > p{
    padding: 5px 5px;
    border-radius: 5px;
    font-size: inherit;
}

.currentDirectory .currentFolder > p span:hover{
    background: none;
}
.currentDirectory .currentFolder > p span:active{
    background: none;
}

.currentDirectory .currentFolder > p:hover{
    cursor: pointer;
    background: #7c7c7c2d;
}

.currentDirectory .currentFolder > p:active{
    cursor: pointer;
    background: #5DB0752d;
    color: #5DB075;
}

.currentFolderName {
    background: #5DB0752d;
    color: #5DB075;
}

.modalCreateFolder {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    width: 280px;
    background: #F6F6F6;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
}

.modalCreateFolder > *{
    margin-bottom: 10px;
    box-sizing: border-box;
}

.modalCreateFolder button{
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.modalCreateFolder input{
    border: 1px solid #E8E8E8;
}