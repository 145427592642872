.wrapCurrentStand {
    position: relative;
    width: 100%;
    padding: 20px 0;
}

.wrapUnity {
    width: 100%;
    max-height: 800px;
    position: relative;
}

.wrapUnity_loading{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(255, 255, 255, 0.726);
}

.wrapUnity_standElemInfo {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    padding: 10px;
    background: white;
    color: #5DB075;
}
.wrapUnity_standElemInfo *{
    color: #5DB075;
}