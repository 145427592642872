body {
    margin: 0;
    font-family: 'Gilroy', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
    font-size: 0;
    vertical-align: top;
}

button {
  cursor: pointer;
  border: none;
}

a, 
a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
}

.green-button {
    width: 100%;
    height: 35px;

    color: white;
    background: #5DB075;
    border: 1px solid #f2f2f2;
    border-radius: 20px;
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 4px;
    color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: 0.4s;
  }

  .grecaptcha-badge{ 
    display: none !important;
  }