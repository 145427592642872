.container {


    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vh;
    height: 80vh;
    margin-left: 40px;

    background: #D4E3ED;
    overflow: hidden;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
}
