.wrapDownloadReport {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(128, 128, 128, 0.658);
    z-index: 1001;
}

.downloadReport{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
}

.downloadReport__title{
    text-align: center;
    padding: 10px;
}

.downloadReport button{
    padding: 10px 20px;
    margin: 10px;
}

.downloadReport_loader{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: scale-down;
    opacity: 1;
    color: white;
    background: #0000008f;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 10px;
}

.downloadReport_loader p{
    text-align: center;
    color: white;
    padding: 10px;
}

.downloadReport_loader img{
    object-fit: scale-down;
    max-width: 100px;
}

.downloadReport__exit{
    position: absolute;
    top: 8px;
    right: 9px;
    font-size: 20px;
    line-height: 11px;
    cursor: pointer;
}

.downloadReport__exit:hover{
    transform: scale(1.1);
    color: red;
}

.downloadReport .downloadReport__link{
    text-align: center;
    text-decoration: underline;
    display: block;
}

.reportBtn{
    margin: 0 auto;
    padding: 10px 20px;
}