.containerLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 147px;
    min-width: 147px;
    min-height: 160px;
    background: #5DB075;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    padding: 10px;
}

.containerLogo p {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 10px;
    color: #FFFFFF;
}

.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    min-height: 99px;
    padding: 10px;
    background: #F6F6F6;
    border: 1px solid #E8E8E8;
    box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}

@media (max-width: 768px) {
    
    .containerLogo{
        width: 130px;
        height: 130px;
    }

    .containerLogo img{
        width: 60px;
    }


    .containerLogo p{
        font-size: 13px;
    }
    
}

@media (max-width: 580px) {
    
    .containerLogo{
        width: 100px;
        height: 100px;
    }

    .containerLogo img{
        width: 40px;
    }

    .containerLogo p{
        margin-top: 2.5px;
        font-size: 9px;
    }
}
