.App {
  text-align: center;
}


input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input:focus-visible,
select:focus-visible{
  outline: none;
}

#toast-container {
  display: block;
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 10000;
}

.toast {
  background: #a1a1a1;
  color: white;
  padding: 10px;
  margin: 2px;
}

* {
  box-sizing: border-box;

}

.LOAD_DATA {
  text-align: center;
}
.mainPages {
  position: relative;
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
}
.wrap-app {
  position: relative;
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
}

.app-pages {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.background-profile {
  position: absolute;
  min-height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  background: #1D3927FF;
  overflow: auto;
  overflow-x: hidden;
  max-height: 100vh;
}

.wrapCheckboxOther {
  align-self: flex-end;
  margin-left: 10px
}

.checkboxOther {
  display: block;
  margin: 0 0 10px 0;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.checkboxOther input[type=checkbox] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;

}

.checkboxOther span {
  display: inline-block;
  position: relative;
  padding: 2px 0 0 30px;
}

.checkboxOther span:before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
  top: 0;
  background: url(./img/square.png) 0 0 no-repeat;
  background-color: white;
  background-size: contain;
}

/* Checked */
.checkboxOther input[type=checkbox] + span:after {
  content: "";
  opacity: 0;
  transition: opacity 0.3s ease;
}

.checkboxOther input[type=checkbox]:checked + span:after {
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 4px;
  top: 4px;
  background: url(./img/tick.png) 0 0 no-repeat;
  background-size: contain;
  opacity: 1;
}

/* Focus */
.focused span:before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

/* Hover */
.checkboxOther span:hover:after {
  filter: brightness(110%);
}

/* Active */
.checkboxOther span:active:before,
.checkboxOther span:active:after {
  filter: brightness(80%);
}


@media (prefers-reduced-motion: no-preference) {

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btnGreen{
  background: #5DB075;
  border: 1px solid #f2f2f2;
  border-radius: 20px;
  color: white;
  cursor: pointer;
}

.btnGreen:hover {
  background: #458858;
}

.btnGreen:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0 50px 100px -20px, rgba(0, 0, 0, 0.3) 0 30px 60px -30px, rgba(10, 37, 64, 0.35) 0 -2px 6px 0 inset;
}

.backgroundLoading{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff9e;
  z-index: 2000;
}

svg.meetingSvg {
  width: 80% !important;
}

.wrapLoaderPage{
  position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff4d;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

/* new css */
.change-pavilion-btn {
  position: fixed;
  z-index: 11;
  top: 50px;
  right: 50px;
}
