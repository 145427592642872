hr {
    border: none;
    height: 1px;
    background-color: #878787;
    width: 99%;
    margin: 0 auto;
}

.filterList{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.container_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 147px;
    height: 130px;

    background: #5DB075;

    border: 1px solid #E8E8E8;
    border-radius: 8px;
}

.container_icon p {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 10px;

    color: #FFFFFF;
}

.icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 70%;
    margin-bottom: 5px;

    background: #F6F6F6;

    border: 1px solid #E8E8E8;
    box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}

.icon svg {
    width: 60%;
}


.filtersContainer {
    margin-left: 50px;
}

.searchContainer {
    position: relative;
    width: 80vw;
    margin: 0 auto;
    overflow-y: auto;
    background: white;
    min-height: 100vh;
    border-radius: 30px;
    padding: 0 10px;
}

.headerContainer {
    display: flex;
    margin: 20px 0 30px;
}

.searchFieldContainer {
    margin-left: 10px;
}

.checkboxOther {
    display: block;
    margin: 0 0 10px 0;
    cursor: pointer;
    user-select: none;
    position: relative;
}

.checkboxOther input[type=checkbox] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;

}

.checkboxOther span {
    display: inline-block;
    position: relative;
    padding: 5px 0 0 40px;
    line-height: 22px;
    margin: 15px 10px;
}

.checkboxOther span:before {
    content: "";
    display: inline-block;
    width: 32px;
    height: 32px;
    position: absolute;
    left: 0;
    top: 0;
    background: url(img/square.png) 0 0 no-repeat;
}

/* Checked */
.checkboxOther input[type=checkbox] + span:after {
    content: "";
    opacity: 0;
    transition: opacity 0.3s ease;
}

.checkboxOther input[type=checkbox]:checked + span:after {
    display: inline-block;
    width: 32px;
    height: 32px;
    position: absolute;
    left: 6px;
    top: -2px;
    background: url(./img/tick.png) 0 0 no-repeat;
    opacity: 1;
}

/* Focus */
.focused span:before {
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

/* Hover */
.checkboxOther span:hover:after {
    filter: brightness(110%);
}

/* Active */
.checkboxOther span:active:before,
.checkboxOther span:active:after {
    filter: brightness(80%);
}

.searchInput {
    display: block;
    width: 340px;
    height: 35px;
    padding-left: 17px;

    color: #5DB075;

    background: #F6F6F6;
    border: 1px solid rgba(135, 135, 135, 0.66);
    box-sizing: border-box;
    border-radius: 8px;
}

.searchInput:focus {
    box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.25);
    color: #5DB075;
}

.listFound {
    height: 61vh;
    overflow-y: scroll;
}

@media (max-width: 850px) {
    .filtersContainer {
        margin-left: 10px;
    }
    .checkboxOther {
        margin: 0;
    }

    .checkboxOther span {
        margin: 0 10px 10px;
    }
}

@media (max-width: 580px) {
    .icon{
        width: 100px;
        height: 100px;
    }

    .icon img{
        width: 40px;
    }

    .icon p{
        font-size: 10px;
    }
}
@media (max-width: 411px) {
    .icon{
        width: 50px;
        height: 50px;
    }

    .icon img{
        width: 30px;
    }

    .icon p{
        font-size: 6px;
    }
}

@media (max-height: 750px) {
    .page_title {
        margin-top: 10px;
    }
    .wrapper {
        margin: 15px 35px 15px 20px;
    }

    .page_title {
        font-size: 18px;
        margin-bottom: 30px;
    }

    .container_icon {
        height: 100%;
        padding-bottom: 5px;
    }
}