.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 60px auto;
}

.title {
    position: absolute;
    width: 30%;
    margin: auto;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: none;
    border-radius: 30px;
}
.button:hover {
    background-color: rgba(93, 176, 117, 0.71);
}

.button a {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding-top: 15px;
    color: #FFFFFF;
}

.disabled {
    background-color: #C4C4C4;
    cursor: not-allowed;
}

.disabled a {
    pointer-events: none;
}

.disabled:hover {
    background-color: #a1a1a1;
}

.container p {
    font-size: 18px;
    text-align: center;
    align-self: center;
    margin: 0 5px;
}