.wrapSelectControl{
    position: relative;
    display: flex;
    /* border: 0.5px solid #7c7c7c; */
    background: #5db07552;
    padding: 5px;
    margin: 10px 0px;
}

.wrapSelectControl p{
    padding: 0;
}

.selectActions{
    margin: 0px 20px;
}

.selectActions, .wrapSelectControl__apply{
    cursor: pointer;
}

.wrapSelectControl__apply{
    margin-right:20px;
}

.wrapSelectControl__close{
    font-size: 32px;
    cursor: pointer;
}

.wrapSelectControl__close:hover{
    color: red;
}