.wrapAppointmentsPage {
    width: 90vw;
    margin: 0 auto;
    padding: 0 50px;
    background: white;
    padding: 0 50px;
    min-height: 100vh;
    border-radius: 30px;
}

.headerContainer{
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 20px 0px 10px;
    height: 190px;
}

.headerContainer__text{
    font-size: 24px;
    line-height: 29px;
    color: #5DB075;
    margin: 0px auto;
}

.headerContainer .headerContainer__close{
    position: absolute;
    top: 18px;
    right: 0;
    font-size: 43px;
    line-height: 20px;
    color: #5DB075;
    margin: 0;
}

.headerContainer__close:hover{
    color: red;
}

.headerContent{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-left: 60px;
}

.headerContentTitle, .headerContentEnter {
    display: flex;
}
.headerContentTitle > a{
    margin: 0;
}

.headerContentEnter label{
    position: relative;
}

.headerContentEnter label:first-child input{
    width: 30vw;
    max-width: 560px;
    height: 43px;
    padding-left: 20px;
    color: #5DB075;
    background: #F6F6F6;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
}

.headerContentEnter input{
    display: block;
}

.headerContentEnter__loader{
    position: absolute;
    bottom: 12px;
    right: 5px;
}

.tablesMeetings {
    display: flex;
    flex-direction: column;
}

.load{
    animation: rotateLoadIcon 3s infinite linear;
    background-color: transparent;
}

.hidden {
    display: none;
}

@keyframes rotateLoadIcon {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
