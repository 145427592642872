.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.container:hover {
    background-color: rgba(167, 255, 194, 0.22);
    border-radius: 10px;
    cursor: pointer;
}

.avatar {
    width: 45px;
    height: 45px;
    margin: 5px 30px 5px 0;
    border-radius: 15px
}

.name,
.nick,
.email,
.company {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
}

.email {
    flex: 2;
}

.nick {
    flex: 1;
}

.name {
    flex: 2;
}

.company {
    flex: 2;
}

.name:hover,
.nick:hover,
.email:hover,
.company:hover {
    /*background-color: #ffffff;*/
    overflow: visible;
    flex: 3;
}

.name,
.nick,
.email {
    border-right: 1px dashed #a1a1a1;
}

.container p{
    margin: 0;
    padding: 5px;
}