.modalPage {
    position: absolute;
    top: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    color: #525252;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
}

.modalContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 610px;
    padding: 25px;

    align-items: center;
    border-radius: 15px;
    background: white;
    box-sizing: border-box;
}

.closeWrapper {
    position: absolute;

    text-align: right;
    margin-top: 0;
    right: 10px;
    top: 10px;
    font-size: 0
}

.closeImage {
    cursor: pointer;
}

.form {
    display: flex;
    flex-direction: column;
}

.whatDownloadText {
    text-align: center;
    font-size: 20px;
}

.selectMedia {
    margin-left: 10px;
    width: 234px;
}

.selectMedia:focus-visible {
    outline: none;
}

.submitInput {
    display: block;
    width: 150px;
    height: 32px;
    margin: 25px auto 0;

    color: #fff;
    background: #5DB075;
    border: 1px solid #f2f2f2;
    border-radius: 0.35rem;
    cursor: pointer;
}

/* 3d model */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
}

.container input {
    width: 85px;
    display: block;
    margin-bottom: 20px;
    padding: 2px 5px;
}

.container input::placeholder {

}

.inputContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 220px;
    height: 50px;
    margin: 10px auto;
    padding: 0 1.2em;

    border: 2px dashed #808080;
    border-radius: 5px;
    transition: border 300ms ease;
    cursor: pointer;
    text-align: center;
}

.inputContainer:hover {
    border: 2px solid #5DB075
}

.inputContainer input[type=file],
.inputContainer input[type=file]:focus{
    width: 0;
    height: 0;

    border: none;
    outline: 0;
    opacity: 0;
    pointer-events: none;
    user-select: none;
}

.inputContainer i {
    display: block;
    font-size: 42px;
    padding-bottom: 16px
}

.uploadFileSpan {
    color: grey;
    transition: 200ms color;
}

.inputContainer:hover .uploadFileSpan,
.inputContainer:hover path {
    fill: #5DB075;
    color: #5DB075;
}

.infoAboutFile {
    margin-top: 50px;
}

.infoAboutFile a {
    color: #5DB075;
    text-decoration: underline;
}

/* image/video iframe */
.iframeIV {
    width: 550px;
    height: 430px;

    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    -moz-transform-origin: top left;
    -webkit-transform-origin: top left;
    -o-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
}

