.container {
    display: flex;
    position: relative;
    width: 100%;
    margin: 0 0 30px 0;
}

.statisticsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.statisticsContainer {
    width: 147px;
    height: 169px;
    padding: 0 10px;

    background-color: #5DB075;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
}

.statisticsContainer p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 8px;

    color: #FFFFFF;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 121px;
    height: 113px;

    background: #F6F6F6;
    border: 1px solid #E8E8E8;
    box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}

.info {
    width: 100%;
    margin-left: 36px;
}

.pageTitle {
    width: 100%;
    font-size: 24px;
    line-height: 29px;
    padding: 0 7px;
    margin: 0 0 24px 0;

    color: #5DB075;
}

.pageTitle2 {
    width: 100%;
    font-size: 20px;
    line-height: 24px;
    padding: 0 7px;
    margin: 0 0 24px 0;
    color: #5DB075;
}

.selectedItem,
.dateTitle {
    font-size: 16px;
    line-height: 19px;

    margin-bottom: 5px;

    color: #A4A3A3;
}

.selectedItem {
    margin-right: auto;
}

.dateTitle {
    display: inline-block;
    width: 100%;
    text-align: end;
}

.date {
    color: #5DB075;
}

.dateContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 66%;
}