.agreementContainer {
    position: absolute;
    width: 100vw;
    z-index: 3;
    background: white;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 11vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10%;
    background: white;
}

.exitContainer {
    display: flex;
    width: 90px;
    padding: 5px 10px 5px 10px;
    background: #dedede;
    border: 1px solid #dedede;
    border-radius: 30px;
    color: #808080;
}

.exitContainer:hover {
    cursor: pointer;
}

.exitContainer:hover svg {
    transform: scale(1.5);
}

.exitContainer:hover svg path{
    fill: red;
}

.exitContainer p {
    font-size: 15px;
    padding: 0;
}

.exitContainer:hover p {
    color: red;
}

.text p {
    padding: 5px 0 0;
}

.text,
.text p{
    color: #444;
}

.textWrapper {
    height: 84vh;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 11vh 10vw 5vh;
    border: thick double #c1c1c1;
    padding: 8px 5px 8px 15px;
    border-right: none;
}

@media screen and (max-height: 488px) {
    .header img {
        max-width: 70px;
    }

    .exitContainer {
        width: 53px;
        padding: 1px 4px 1px 4px;
    }

    .exitContainer p {
        font-size: 10px;
    }

    .exitContainer svg {
        width: 10px;
    }
}

@media screen and (max-height: 488px) {
    .header img {
        max-width: 50px;
    }

    .exitContainer {
        width: 53px;
        padding: 1px 4px 1px 4px;
    }

    .exitContainer p {
        font-size: 9px;
    }

    .exitContainer svg {
        width: 8px;
    }
}
